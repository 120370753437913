import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {IPartnerData} from 'src/app/interfaces/general/profile-definitions/partner-data.interface';
import {ProfileService} from 'src/app/modules/profile/profile.service';
import {
    DeletePartnerDataItemSharing,
    GetPartnerDataItems,
} from 'src/app/store/partner-data/partner-data.actions';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {firstValueFrom, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

export interface IPartnerDataItemsState {
    partnerDataItems: IPartnerData[];
    loading: boolean;
    hasValue: boolean;
    error: any;
}

@State<IPartnerDataItemsState>({
    name: 'partnerDataItemsState',
    defaults: {
        partnerDataItems: [],
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class PartnerDataItemsState {
    constructor(
        private profileService: ProfileService,
        private store: Store,
    ) {}

    @Selector()
    public static partnerDataItems(state: IPartnerDataItemsState): IPartnerData[] {
        return state.partnerDataItems;
    }

    @Selector()
    public static loading(state: IPartnerDataItemsState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IPartnerDataItemsState): boolean {
        return state.hasValue;
    }

    @Action(GetPartnerDataItems)
    public async getPartnerDataItems(
        ctx: StateContext<IPartnerDataItemsState>,
        {additionalType}: GetPartnerDataItems,
    ): Promise<void> {
        try {
            ctx.patchState({loading: true});
            const typeParameter = additionalType === 'all' ? undefined : additionalType;
            const partnerData: ItemPayload<IPartnerData> = await firstValueFrom(
                this.profileService.getPartnerData(10000, typeParameter),
            );
            ctx.patchState({
                partnerDataItems: partnerData.data,
                hasValue: partnerData.data.length > 0,
                loading: false,
                error: null,
            });
        } catch (e) {
            console.error(e);
            ctx.patchState({
                partnerDataItems: [],
                hasValue: false,
                loading: false,
                error: e.error ?? e.message ?? e.statusText,
            });
        }
    }

    @Action(DeletePartnerDataItemSharing)
    public deletePartnerDataSharing(
        ctx: StateContext<IPartnerDataItemsState>,
        {name}: IPartnerData,
    ): Observable<any> {
        ctx.patchState({loading: true});
        return this.profileService.removePartnerDataSharing(name).pipe(
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
            tap(() => {
                this.store.dispatch(new GetPartnerDataItems());
            }),
        );
    }
}
