export class GetPartnerDataItems {
    public static readonly type = '[PartnerData] Get partner data items';

    constructor(public additionalType?: string) {}
}

export class GetPartnerDataItem {
    public static readonly type = '[PartnerData] Get partner data item';

    constructor(public name?: string) {}
}

export class CreatePartnerDataItem {
    public static readonly type = '[PartnerData] Create partner data item';
}

export class EditPartnerDataItem {
    public static readonly type = '[PartnerData] Edit partner data item';

    constructor(public name: string) {}
}

export class DeletePartnerDataItem {
    public static readonly type = '[PartnerData] Delete partner data item';

    constructor(public name: string) {}
}

export class DeletePartnerDataItemSharing {
    public static readonly type = '[PartnerData] Delete partner data item sharing';

    constructor(public name: string) {}
}
